import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { BACKGROUNDS } from 'src/app/mocks/backgrounds';
import { Noun } from 'src/app/mocks/noun';
import { getRandomInt } from 'src/app/utils/math';

@Component({
  selector: 'app-input-salary',
  templateUrl: './input-salary.component.html',
  styleUrls: ['./input-salary.component.scss'],
})
export class InputSalaryComponent implements OnInit {

  @Input() noun: Noun;
  backgrounds = BACKGROUNDS
  contentBackground: string

  value

  constructor(
    public modalCtrl: ModalController
  ) {
    this.contentBackground = this.backgrounds[getRandomInt(0,3)]
  }

  ngOnInit() {
    this.value = this.noun.value
  }

  save(){
    this.modalCtrl.dismiss(true)
    this.noun.value = Number(this.value)
  }

}
