import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { HomePage } from './views/home/home.page';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./views/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'customdev',
    loadChildren: () => import('./views/custom-development/custom-development.module').then( m => m.CustomDevelopmentPageModule)
  },
  {
    path: 'salary',
    loadChildren: () => import('./views/salary/salary.module').then( m => m.SalaryPageModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./views/profile/profile.module').then( m => m.ProfilePageModule)
  },
  {
    path: 'faq',
    loadChildren: () => import('./views/faq/faq.module').then( m => m.FaqPageModule)
  },
  {
    path: 'portfolio',
    loadChildren: () => import('./views/portfolio/portfolio.module').then( m => m.PortfolioPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
